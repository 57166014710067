import styled, { css } from 'styled-components'
import Typography from '@ntuctech/devex-tangram/Typography'
import SvgAlertFilled from '@ntuctech/devex-tangram/Icon/SvgAlertFilled'
import Toast from '@ntuctech/devex-tangram/Toast'
import Grid from '@ntuctech/devex-tangram/Grid'
import Box from '@ntuctech/devex-tangram/Box'
import Popup from '../../Popup/Popup'
import Image from '../../Image/Image'
import Link from '@ntuctech/devex-tangram/Link'
import SvgBack from '@ntuctech/devex-tangram/Icon/SvgBack'
import SvgArrowRight from '@ntuctech/devex-tangram/Icon/SvgArrowRight'
import SvgClose from '@ntuctech/devex-tangram/Icon/SvgClose'
import Checkbox from '@ntuctech/devex-tangram/Checkbox'
import ErrorIcon from '../assets/voucher-error-icon.svg'
import { SCREEN_SIZE } from '../../../lib/Media'
import { ContentSection, PaddingBox, Skeleton } from '../../../lib/commonStyles'

const SC = {}
SC.StyledPopup = styled(Popup)`
  overflow: hidden;
  ${SCREEN_SIZE.From.MobileL} {
    min-width: 22.5rem;
    .popup-body {
      margin: 6.0625rem auto 3.5rem;
    }
  }
  .details-tab {
    ._ndsTab {
      padding: 0.75rem 1.5rem 0.8125rem;
      width: 100%;
    }
    ._ndsTab:hover,
    ._ndsTab:focus {
      background: none;
    }
    ._ndsTab__label {
      font-size: 0.875rem;
    }
  }
`

SC.VoucherCodepopupWrpContainer = styled.div`
  #body-container::-webkit-scrollbar {
    display: none;
  }
`
SC.SideSheetViewDetailVoucher = styled.div`
  height: 9.75rem;
  background: #f6f6f6;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding: 1.5rem 2.5rem 0;
  ${SCREEN_SIZE.Below.MobileL} {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1.5rem 1.5rem 0;
  }
`

SC.StyledPopupContainer = styled.div`
  background-color: #fff;
  position: relative;
  overflow: hidden;

  h6 {
    font-weight: 700;
  }
  .apply-btn {
    box-shadow: none;
    margin: 0 1.5rem;
    width: calc(100% - 3rem);
    bottom: 1.5rem;
    position: fixed;
    &:disabled {
      background-color: rgb(242, 242, 242);
      color: rgb(204, 204, 204);
    }
    ${SCREEN_SIZE.From.MobileL} {
      position: absolute;
      margin: 1.5rem;
      bottom: 0;
    }
  }

  ${SCREEN_SIZE.Only.Mobile} {
    width: 100vw;
    height: 100vh;
  }
  ${SCREEN_SIZE.From.MobileL} {
    height: ${props => (props.code ? 'auto' : '100%')};
    max-width: 27.5rem;
    max-height: ${props => (props.wallet ? '64rem' : '40rem')};
    border-radius: 0.5rem;
    min-width: 22.5rem;
  }
`

SC.ScrollableBox = styled(Box)`
  overflow-y: scroll;
  width: ${props => (props.isSideSheetEnable ? 'auto' : '100%')};
  margin: ${props =>
    props.isSideSheetEnable ? '0 2.5rem 1rem' : '1.5rem 1.5rem 0'};
  padding-bottom: ${props => {
    if (props.notloggedin) {
      return '1.5rem'
    } else if (props.isSideSheetEnable) {
      return '0'
    } else {
      return '5.5rem'
    }
  }};
  max-width: calc(100% - 3rem);
  height: ${props => {
    if (props.notloggedin) {
      return '7.4rem'
    } else if (!props.notloggedin && props.count) {
      return 'calc(100vh - 17.5rem);'
    } else if (props.isSideSheetEnable) {
      return '100vh;'
    } else {
      return 'calc(100vh - 14.5rem);'
    }
  }};
  ${props =>
    props.notloggedin &&
    'border-bottom: 0.0625rem solid #d8d8d8;margin-bottom:1.5rem;'};

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  ${SCREEN_SIZE.Below.MobileL} {
    margin: ${props =>
      props.isSideSheetEnable ? '0 1rem 0' : '1.5rem 1.5rem 0'};
    max-width: ${props => props.isSideSheetEnable && '100%'};
  }

  ${SCREEN_SIZE.From.MobileL} {
    height: ${props => {
      if (props.notloggedin) {
        return '7.4rem'
      } else if (
        !props.notloggedin &&
        props.count &&
        !props.isSideSheetEnable
      ) {
        return '26.5rem;'
      } else if (props.isSideSheetEnable) {
        return '100vh;'
      } else {
        return '29.5rem'
      }
    }};
  }
`
SC.StyledCodeBox = styled(Box)`
  padding: ${props => (props.isSideSheetEnable ? 0 : '0 1.5rem')};
  min-width: 19.5rem;
`
SC.StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 3.25rem;
  ${props => props.isSideSheetEnable && 'position: absolute;'}
  top: ${props => {
    if (props.isSideSheetEnable) {
      return '0;'
    } else if (props.count && !props.isSideSheetEnable) {
      return '11.375rem;'
    } else {
      return '8.25rem;'
    }
  }}
  bottom: ${props => (props.isSideSheetEnable ? '' : 0)};
  left: 0;
  right: 0;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /*To hide scrollbar in firefox */
  -webkit-overflow-scrolling: touch;
  .grid-box {
    padding: 0;
  }
  h6.available-vouchers {
    float: left;
    padding: ${props => (props.isSideSheetEnable ? '0 0 1rem' : '0 0 0.75rem')};
    width: 100%;
  }
  h6.not-available-vouchers {
    ${props => props.isSideSheetEnable && 'margin: 0.75rem 0 0.25rem 0;'}
    float: left;
    padding: 0.75rem 0 0.75rem 0;
    width: 100%;
    span {
      display: block;
      font-weight: 400;
    }
  }
`
SC.StyledWrapper = styled(Box)`
  padding: 0 1.5rem;
`
SC.Toast = styled(Toast)`
  margin-top: 1.875rem;
`
SC.PopupHeader = styled(Box)`
  margin-bottom: ${props => !props.usenow && '1rem;'};
  background: white;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  &.voucher-popup-header {
    margin-bottom: 0;
  }
  &[data-back-btn='true'] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
  }
  &[data-back-btn='false'] {
    .close-btn {
      top: -1.5625rem;
      position: relative;
    }
    .voucher-detail-close-btn {
      top: 0.625rem;
    }
  }
  padding: 0.75rem 0;
  h6 {
    text-align: center;
  }
`
SC.SelectedVoucherCount = styled(Box)`
  background: #e8f0fd;
  border-radius: 0.125rem;
  font-size: 0.75rem;
  line-height: 1.6;
  width: 100%;
  color: #333333;
  border: 0.125rem solid #e8f0fd;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: ${props => (props.isSideSheetEnable ? 0 : '0.5rem')};
  text-align: center;
  img {
    vertical-align: middle;
    margin-right: 0.5rem;
  }
  svg {
    width: 1rem;
    height: 1rem;
  }
`
SC.BackBtn = styled(SvgBack)`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  float: left;
  margin-left: 0.75rem;
`
SC.PopupCloseBtn = styled(SvgClose)`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  float: right;
  margin-right: 0.75rem;
  ${props =>
    props.isVoucher &&
    'width: 1.5rem; height: 1.5rem;min-width: 1.5rem;min-height: 1.5rem;max-width: 1.5rem;max-height: 1.5rem; top: -1.25rem !important'}
`

SC.DetailPopupCloseBtn = styled(SvgClose)`
  cursor: pointer;
  float: right;
  margin-right: 0.75rem;
  position: absolute;
  right: 0;
  ${props =>
    props.isVoucher &&
    'width: 1.5rem; height: 1.5rem;min-width: 1.5rem;min-height: 1.5rem;max-width: 1.5rem;max-height: 1.5rem; top: 0.625rem'}
`

SC.BackLink = styled.a`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 16px;
  top: 12px;
`

SC.BackIcon = styled(SvgBack)`
  margin-right: 0.5rem;
`

SC.InputWrap = styled.div`
  width: 100%;
  min-width: 19.5rem;
  input {
    border: 0.0625rem solid #666666;
    box-sizing: border-box;
    border-radius: 0.25rem;
    width: 100%;
    margin: ${props => {
      if (props.isSideSheetEnable) {
        return '0.5rem 0 0;'
      } else if (props.code && !props.isSideSheetEnable) {
        return '0 0 0.5rem;'
      } else {
        return '0'
      }
    }};
  }
  label {
    font-size: 1rem;
    line-height: 1.5;
    color: #999999;
    font-weight: normal;
    top: 0.75rem;
  }
  ._ndsFormControl__label {
    display: none;
  }
  ._ndsFormControl {
    ${props => props.isSideSheetEnable && 'margin-bottom: 0 !important;'}
  }
  & svg {
    margin-right: 0.25rem;
  }
`

SC.CheckboxLabel = styled.label`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  ._ndsCheck--disabled {
    outline: none;
    &:focus-visible {
      outline: none;
    }
    ._ndsCheck__styled,
    ._ndsCheck__styled:focus-visible {
      border-color: #cccccc;
      outline: none;
    }
  }
`

SC.StyledBoxContainer = styled('div')`
  background-color: #f6f6f6;
  padding: 1rem 0;
`

const sharedStyle = css`
  display: flex;
  padding: 0.75rem 0;
  cursor: pointer;
  text-align: left;
  width: 100%;
  position: relative;
  align-items: center;
  ${props => props.wallet && 'margin: 0 2.5rem;'}
  border: ${props => (props.error ? '1px solid #c10b3a' : '1px solid #eaeaea')};
  background: #ffffff;
  &.checkboxSelectAll {
    margin-bottom: ${props => (props.isSideSheetEnable ? '0.75rem' : '1rem')};
    padding: 0.75rem 0rem 0.75rem 0.5rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  &[data-popup='true'] {
    box-shadow: none;
    width: auto;
    .top-icon,
    .bottom-icon {
      background-image: radial-gradient(#f4f5f7 94%, #f4f5f7 30%, #f4f5f7 30%);
    }
  }
  .top-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: -0.625rem;
    left: 3.25rem;
    border-radius: 50%;
    background-image: radial-gradient(#ffffff 94%, #ffffff 30%, #ffffff 30%);
    box-shadow: ${props => (props.error ? '0 1px #c10b3a' : '0 1px #eaeaea')};
    ${props => props.error && 'background-color: white;'}
  }

  .bottom-icon {
    width: 1rem;
    height: 1rem;
    bottom: -0.625rem;
    left: 3.25rem;
    border-radius: 50%;
    position: absolute;
    background-image: radial-gradient(#ffffff 94%, #ffffff 30%, #ffffff 30%);
    transform: rotate(180deg);
    box-shadow: ${props => (props.error ? '0 1px #c10b3a' : '0 1px #eaeaea')};
    ${props => props.error && 'background-color: white;'}
  }

  border-radius: 0.25rem;
  align-items: center;
  ${props => props.count && 'margin-top: 0.5rem;'}

  .text-info {
    position: relative;
    flex-direction: row;
    width: calc(100% - 80px);
  }
  .qrcode-box {
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: auto;
  }
  &[data-popup='false'] .qrcode-box {
    cursor: pointer;
  }
  &[data-notapplicablevouchers='true'] {
    cursor: not-allowed;
    .checkbox-wrapper {
      pointer-events: none;
    }
    img {
      filter: grayscale(100%);
    }
    &[data-popup='true'] {
      box-shadow: none;
      width: auto;
      .top-icon,
      .bottom-icon {
        background-image: radial-gradient(
          #ffffff 94%,
          #ffffff 30%,
          #ffffff 30%
        );
      }
    }
  }
  .grid-container {
    padding: 0;
  }
  .styled-grid {
    padding: 0;
  }
  &[data-popup='false']:hover {
    border-color: #cccccc;
    .top-icon,
    .bottom-icon {
      box-shadow: 0 1px #cccccc;
    }
    ._ndsCheck__styled {
      border-color: rgb(32, 112, 239);
      box-shadow: rgb(163, 194, 245) 0rem 0rem 0rem 0.125rem;
    }
  }
  &[data-popup='false']:active {
    border-color: #2070ef;
    .top-icon,
    .bottom-icon {
      box-shadow: 0 1px #2070ef;
    }
  }
`

SC.StyledBox = styled('label')`
  ${sharedStyle}
`

SC.StyledSelectAll = styled('button')`
  ${sharedStyle}
`

SC.QrIconBox = styled(Box)`
  max-width: 3.75rem;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  .voucher-icon {
    opacity: 0;
    height: 0;
  }
`
SC.DashedBox = styled(Box)`
  border-left: ${props =>
    props.isNewVoucherEnable ? 0 : '0.0625rem dashed #ebecee'};
`
SC.InfoBox = styled(Box)`
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  &[data-popup='false'] {
    cursor: pointer;
  }
`
SC.StyledLink = styled(Link)`
  float: right;
  margin-left: 1rem;
  font-weight: bold;
  visibility: hidden;
  &[data-link-past='true'] {
    color: #4d4d4d;
  }
  &:hover {
    box-shadow: none;
    border: none;
  }
  svg {
    vertical-align: bottom;
  }
  span,
  .desktop {
    display: none;
  }
  ${SCREEN_SIZE.From.MobileL} {
    margin-left: 0;
    .mobile {
      display: none;
    }
    span,
    .desktop {
      display: inline;
    }
  }
`
SC.StyledSubHead1 = styled(Typography)`
  font-family: Lato, sans-serif;
  max-width: 15.125rem;
`
SC.StyledSubHead5 = styled(Typography)`
  overflow: hidden;
  font-family: Lato, sans-serif;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 15.125rem;
`

SC.StyledSubHead2 = styled(Typography)`
  font-weight: bold;
  color: #999999;
  font-family: Lato, sans-serif;
  padding: 0.25rem 0;
  width: 100%;
  word-break: break-word;
  ${SCREEN_SIZE.From.MobileL} {
    max-width: 16.5rem;
  }
`
SC.Validity = styled(Typography)`
  color: ${props => (props.expired ? '#ea6100' : '#999999')};
  margin-bottom: ${props => (props.error ? '0.5rem' : '0.625rem')};
  font-family: Lato, sans-serif;
`

SC.StyledSubHead3 = styled(Typography)`
  padding: 0;
  width: 100%;
  overflow: hidden;
  font-weight: 700;
  font-family: Lato, sans-serif;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #666666;
  ${SCREEN_SIZE.From.MobileL} {
    max-width: 15.125rem;
  }
`
SC.StyledSubHead4 = styled(Typography)`
  font-family: Lato, sans-serif;
  padding: 0 0 0.25rem;
  width: 100%;
  color: ${props => (props.expired ? '#ea6100' : '#666666')};
  ${SCREEN_SIZE.From.MobileL} {
    max-width: 15.125rem;
  }
`

SC.VoucherError = styled(Typography)`
  &.error-text {
    color: #c10b3a;
    max-width: 9.875rem;
  }
`
SC.ErrorBox = styled(Box)`
  display: flex;
  margin-top: 0.75rem;
`
SC.SvgError = styled(SvgAlertFilled)`
  margin-right: 0.5rem;
`
SC.StyledCheckBox = styled(Checkbox)`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.125rem;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
  border: 1px solid black;
`

SC.StyledErrorBox = styled.div`
  text-align: center;
  margin-top: 2rem;
  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.4;
    text-align: center;
    color: #666666;
    margin-top: 1.25rem;
  }
  &.error button {
    margin-top: 1rem;
  }
  ${SCREEN_SIZE.From.MobileL} {
    min-height: ${props => (props.count ? '15.5rem' : '15rem')};
  }
`
SC.StyledImageWrapper = styled.div`
  margin-top: 0.25rem;
`
SC.ImageErrorLoader = styled.div`
  div {
    margin: 0.5rem auto auto;
  }
`

SC.StyledViewDetails = styled.div`
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #1454b8;
  &:hover {
    text-decoration: underline;
  }
`

SC.LoadingContainer = styled.div`
  span {
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
  }
`

SC.StyledVoucherImage = styled(Image)`
  object-fit: contain;
  object-position: center;
  ${props =>
    props.isPast && 'filter: grayscale(100%);'}; // display image in B&W
`

export default {
  ...SC,
  Grid,
  Image,
  Box,
  Typography,
  SvgArrowRight,
  ErrorIcon,
  ContentSection,
  PaddingBox,
  Skeleton,
}
